import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { Profile } from '@smartswap/client-api';
import { getMyProfile } from '@/services/PersonalService';
import { FeaturesContext } from './FeaturesContext';
import useAsyncEffect from 'use-async-effect';
import { AuthContext } from './AuthContext';
import { PushContext } from './PushContext';

export const MemberContext = createContext<Partial<Profile> & { clear: () => void; update: () => void }>({
	clear: () => null,
	update: () => null,
});

export const MemberContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [profile, setProfile] = useState<Partial<Profile>>();
	const { reload: reloadFeatures } = useContext(FeaturesContext);
	const isLoggedIn = useContext(AuthContext).isLoggedIn();
	const { addHandler, removeHandler } = useContext(PushContext);

	const update = useCallback(async () => {
		setProfile(await getMyProfile());
		reloadFeatures();
	}, [reloadFeatures]);

	useAsyncEffect(update, [isLoggedIn]);
	useEffect(() => {
		addHandler('ProfileUpdated', update);
		return () => removeHandler('ProfileUpdated', update);
	}, [addHandler, removeHandler, update]);

	function clear() {
		setProfile({});
		reloadFeatures();
	}

	return <MemberContext.Provider value={{ ...profile, clear, update }}>{children}</MemberContext.Provider>;
};

export const useMemberContext = () => useContext(MemberContext);
