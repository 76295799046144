import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { CardHeader } from '@/components/Card/Card';
import { Button } from '@/components/Button';
import { InputField } from '@/components/Input';
import { Formik, FormikProps } from 'formik';
import { useNavigate } from 'react-router-dom';
import { login } from '@/services/Auth';
import * as Yup from 'yup';
import { AuthContext } from '@/contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { Logo } from '@/Icons';
import { PageCard, PageCardContent, CardLogoWrapper, PageCardWrapper } from '@/components/Card/PageCard';

interface LoginFormProps {
	email: string;
	password: string;
}

const CardWithLogo = styled(PageCard)`
	margin: 70px 0 28px;
`;

const ForgotPasswordLink = styled.a`
	width: 100%;
	display: block;
	text-align: right;
	cursor: pointer;
	color: var(--login-color-link);

	margin: 1rem 0;

	&:hover {
		text-decoration: underline;
	}
`;

const InputError = styled.div`
	margin: var(--step) 0;
	color: var(--color-error);
`;

const initialValues: LoginFormProps = {
	password: '',
	email: '',
};

const LoginForm = ({ handleSubmit }: FormikProps<LoginFormProps>) => {
	const { t } = useTranslation('auth');
	const navigate = useNavigate();

	return (
		<form onSubmit={handleSubmit}>
			<InputField type={'email'} name={'email'} label={t('form.email')} />
			<InputField type={'password'} name={'password'} label={t('form.password')} />

			<ForgotPasswordLink onClick={() => navigate('/forgot', { replace: true })}>
				{t('forgotPassword', { postProcess: 'md' })}
			</ForgotPasswordLink>

			<Button $width={'full'} type={'submit'}>
				{t('form.login')}
			</Button>
		</form>
	);
};

export const LoginPage: React.FC = () => {
	const [failed, setFailed] = useState<boolean>(false);
	const auth = useContext(AuthContext);
	const { t } = useTranslation('auth');
	const navigate = useNavigate();

	const loginValidationSchema = Yup.object().shape({
		password: Yup.string().required(t('form.validation.passwordIsRequired')),
		email: Yup.string().required(t('form.validation.emailIsRequired')),
	});

	async function submitForm(values: LoginFormProps) {
		try {
			auth.login(await login(values.email, values.password));
			navigate('/offers', { replace: true });
		} catch (e) {
			setFailed(true);
		}
	}

	return (
		<PageCardWrapper>
			<CardWithLogo>
				<CardLogoWrapper>
					<Logo />
				</CardLogoWrapper>

				<CardHeader>{t('loginToAccount')}</CardHeader>
				<PageCardContent>
					<Formik
						initialValues={initialValues}
						onSubmit={submitForm}
						component={LoginForm}
						validateOnChange={false}
						validateOnBlur={true}
						validationSchema={loginValidationSchema}
						failed={failed}
					/>
					{failed && <InputError>{t('invalidCredentials')}</InputError>}
				</PageCardContent>
			</CardWithLogo>
		</PageCardWrapper>
	);
};
