import styled, { css } from 'styled-components';

const buttonVariants = {
	outline: css`
		color: var(--color-text);
		background-color: transparent;
		border: 1px solid var(--current-button-border-color, rgba(215, 225, 234, 0.8));
	`,
};

export const buttonStyle = css`
	position: relative;
	font-family: 'InterUI';
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	min-height: 36px;
	padding: 7px 12px;
	cursor: pointer;
	box-sizing: border-box;
	text-wrap: nowrap;

	color: var(--current-button-color);
	background-color: var(--current-button-background);

	border: none;
	border-radius: 5px;

	&:disabled {
		background-color: #d7e1ea;
		color: #778699;

		&:hover {
			filter: none;
		}
	}

	&:hover {
		filter: brightness(1.05);
	}
`;

const buttonPalette = {
	primary: css`
		--current-button-background: var(--color-primary);
		--current-button-color: var(--color-default);
		--current-button-border-color: rgba(215, 225, 234, 0.8);
		--current-button-icon-color: var(--color-default);
	`,
	ghost: css`
		--current-button-background: var(--color-default);
		--current-button-color: var(--color-text);
		--current-button-border-color: var(--color-default);
		--current-button-icon-color: var(--color-text);
	`,
	danger: css`
		--current-button-background: var(--color-danger);
		--current-button-color: var(--color-default);
		--current-button-border-color: var(--color-danger);
	`,
};

interface ButtonProps {
	$variant?: 'outline';
	$color?: keyof typeof buttonPalette;
	$width?: 'full';
}

export const Button = styled.button<ButtonProps>`
	${(p) => (p.$color ? buttonPalette[p.$color] : buttonPalette.primary)}
	${buttonStyle};

	width: ${(p) => (p.$width === 'full' ? '100%' : 'initial')};

	${(p) => p.$variant && buttonVariants[p.$variant]}

	height: min-content;

	path {
		fill: var(--current-button-icon-color);
	}
`;
