import React, { CSSProperties, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Card } from '@/components/Card/Card';

const Wrapper = styled(Card)`
	width: initial;
	font-family: Poppins;
	box-sizing: border-box;
	padding: 32px;
	font-weight: 600;
	font-size: 24px;
	position: relative;
	max-width: 320px;
	height: 300px;
	color: #ffffff;
	background: linear-gradient(83.99deg, #696cff -8.3%, #b992ff 139.65%);
	cursor: ${(p) => (p.onClick ? 'pointer' : 'initial')};
`;

interface MessageCardProps {
	className?: string;
	style?: CSSProperties;
	onClick?: () => void;
}

export const MessageCard: React.FC<PropsWithChildren<MessageCardProps>> = ({ children, ...props }) => {
	return <Wrapper {...props}>{children}</Wrapper>;
};
