import { Card } from '@/components/Card/Card';
import styled from 'styled-components';
import { screenSize } from '@/ScreenSize';
import { fillScreenHeight, boxShadow } from '@/styles/GlobalStyle';

export const PageCard = styled(Card)`
	display: flex;
	flex-flow: column;
	max-width: 410px;
	width: 100%;
	align-items: center;
  	position: relative;
    ${boxShadow(4)};

	@media screen and ${screenSize.mobile} {
		box-shadow: none;
		--step: 8px;
	}
`;

export const PageCardWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  ${fillScreenHeight};

  //TODO: Remove temp box sizing
  & * {
    box-sizing: border-box;
  }

  a {
    color: #696cff;
  }

  @media screen and ${screenSize.mobile} {
    background: white;
  }
`;


export const PageCardContent = styled.div`
	font-family: 'InterUI';
	font-weight: 500;
	font-size: 12px;
	padding: 24px;
  	padding-bottom: 42px;
	width: 100%;
	max-width: 340px;

	@media screen and ${screenSize.mobile} {
		--step: 8px;
	}
`;

export const CardLogoWrapper = styled.div`
	width: 100px;
	height: 24px;
	position: relative;

	svg {
		transform: translateY(-50%);
		width: 100px;
		height: 100px;
	}
`;
