import styled from 'styled-components';
import categoryColors from './categoryColors.json';
import React, { CSSProperties } from 'react';

const Wrapper = styled.div`
	padding: 20px;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
  
	& img {
	  width: 33%;
	}
`;

interface CategoryPlaceholderProps {
	slug?: string;
	className?: string;
	style?: CSSProperties;
}

export const CategoryPlaceholder: React.FC<CategoryPlaceholderProps> = ({ slug = 'other', className, style }) => {
	const backgroundColor = `${(categoryColors as { [key: string]: string })[slug]}16`;

	return (
		<Wrapper style={{ backgroundColor, ...style }} className={className}>
			<img width="auto" src={`/iconss/categories/${slug}.svg`} alt={slug} />
		</Wrapper>
	);
};
