import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { DefaultApi, Features } from '@smartswap/client-api';
import { config } from '@/configuration';
import useAsyncEffect from 'use-async-effect';
import { AuthContext } from './AuthContext';
import { PushContext } from './PushContext';

const defaultState: Features = {
	isLoggedIn: false,
};

type FeaturesProps = Features & {
	reload: () => void;
};

export const FeaturesContext = createContext<FeaturesProps>({} as FeaturesProps);

const api = new DefaultApi(config);
export const FeaturesContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [features, setFeatures] = useState<Features>(defaultState);
	const { isLoggedIn } = useContext(AuthContext);
	const { addHandler, removeHandler } = useContext(PushContext);

	const reload = useCallback(async () => {
		setFeatures(await api.featuresGet());
	}, []);

	useAsyncEffect(reload, [isLoggedIn]);
	useEffect(() => {
		addHandler('ProfileUpdated', reload);
		return () => removeHandler('ProfileUpdated', reload);
	}, [addHandler, reload, removeHandler]);

	return <FeaturesContext.Provider value={{ ...features, reload }}>{children}</FeaturesContext.Provider>;
};

export const useFeaturesContext = () => useContext(FeaturesContext);

export const LoadFeatures: React.FC = () => {
	const features = useContext(FeaturesContext);
	useAsyncEffect(features.reload, []);
	return <></>;
};
